import { Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { MdOutlinePolicy } from "react-icons/md";
export const PrivacyPolicyContainer = () => {
  return (
    <>
      <Flex
        width="100%"
        className="checkout-container"
        sx={{
          bgColor: "#fff",
          boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
          borderRadius: "4px",
          p: "20px",
          gap: "40px",
          flexDir: "column",
          height: "92%",
          //   position: 'relative',
          overflow: "scroll",
          m: "2% 2%",
        }}
      >
        <Flex
          gap="10px"
          alignItems="center"
          pb="5px"
          borderBottom="2px solid #152D73"
          justifyContent="space-between"
        >
          <Flex gap="10px">
            <Icon
              as={MdOutlinePolicy}
              boxSize="1.7em"
              sx={{
                transition: "transform 0.15s",
              }}
            />
            <Flex
              sx={{
                fontSize: [16, 16, 18, 20, 22, 24],
                fontWeight: 500,
                m: "auto 0px",
              }}
            >
              Privacy Policy
            </Flex>
          </Flex>{" "}
        </Flex>
        <Flex
          sx={{ width: "100%", height: "100%", flexDir: "column", gap: "10px" }}
        >
          <Flex gap={"10px"}>
            1. Privacy and Cookie Policy:{" "}
            <a
              target="_blank"
              href="https://konnectzit.com/privacy-cookie-policy"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              https://konnectzit.com/privacy-cookie-policy
            </a>
          </Flex>{" "}
          <Flex gap={"10px"}>
            2. Privacy controls and Cookie:{" "}
            <a
              target="_blank"
              href="https://konnectzit.com/privacy-controls"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              https://konnectzit.com/privacy-controls
            </a>
          </Flex>{" "}
          <Flex gap={"10px"}>
            3. Terms and Conditions:{" "}
            <a
              target="_blank"
              href="https://konnectzit.com/terms-and-conditions"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              https://konnectzit.com/terms-and-conditions
            </a>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
