import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Button,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
} from "@chakra-ui/react";
import { svg, svg_1 } from "../../assets/objectSVG";
import { useDispatch, useSelector } from "react-redux";
import { setRoute } from "../../store/dashSlice";
import AuthTokenService from "../../utils/AuthTokenService";
import { DashButton } from "../styled";
import { useTranslation } from "react-i18next";
import { TbCalendarDollar } from "react-icons/tb";
import { setEditMode } from "../../store/slice/canvasSlice";
import { MdOutlinePolicy } from "react-icons/md";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { TbNotes } from "react-icons/tb";
import { MdOutlineCookie } from "react-icons/md";
const menuItems = [
  {
    key: 1,
    icon: svg_1.home,
    label: "Dashboard",
    route: "/dashboard",
  },
  {
    key: 2,
    icon: svg_1.apps,
    label: "Apps",
    route: "/apps",
  },
  {
    key: 3,
    icon: svg_1.konnect,
    label: "Konnectz",
    route: "/konnectz",
  },
  {
    key: 4,
    icon: svg_1.history,
    label: "History",
    route: "/history",
  },
];

const helpItems = [
  {
    key: 1,
    icon: svg_1.help,
    label: "Get Help",
    route: "https://docs.konnectzit.com/en",
  },
];

export const NonAuthShell = () => {
  return (
    <Flex
      sx={{
        backgroundColor: "rgb(243,247,255)",
        width: "100%",
        height: "100%",
        margin: "0px",
        overflow: "auto",
      }}
    >
      <Flex
        sx={{
          m: "auto",
          width: "90%",
          maxWidth: "1400px",
          borderRadius: "4px",
          backgroundColor: "#f5f6f6",
          boxShadow: "20px 0px 50px 20px rgb(21,46,115,0.2)",
        }}
      >
        {/* This is where the nested route is getting rendered */}
        <Outlet />
      </Flex>
    </Flex>
  );
};

export const AuthShell = () => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  return (
    <Flex
      sx={{
        backgroundColor: "rgb(243,247,255)",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        margin: "0px",
      }}
    >
      <Headerbar />
      <Navbar />
      <Flex
        sx={{
          height: "100%",
          flexDirection: "column",
          transition: "width 0.35s",
          height: "100%",
          px: ["60px", "60px", "80px", "80px", "100px", "220px"],
        }}
      >
        <Flex
          sx={{
            maxWidth: "2000px",
            width: "100%",
            height: "100%",
            m: "0px auto",
          }}
        >
          {/* This is where the nested route is getting rendered */}
          <Outlet />
        </Flex>
      </Flex>
    </Flex>
  );
};

const Headerbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expandPrivacy, setExpandPrivacy] = useState(false);
  const { t } = useTranslation();
  const logout = () => {
    dispatch(setRoute("Dashboard"));
    AuthTokenService.clear();
    navigate("/logout");
  };

  return (
    <Flex
      sx={{
        height: "10%",
        minHeight: "64px",
        maxHeight: "100px",
        backgroundColor: "#152d73",
        px: ["60px", "60px", "80px", "80px", "100px", "220px"],
        alignItems: "center",
        boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
      }}
    >
      <Flex
        width="100%"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "2000px",
          m: "auto",
        }}
      >
        <Flex
          sx={{
            gap: "10px",
            fontSize: [30, 30, 30, 40, 40, 40],
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 600,
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={() => navigate("/dashboard")}
        >
          <Flex
            sx={{
              height: ["30px", "30px", "30px", "35px", "40px", "40px"],
              width: ["30px", "30px", "30px", "35px", "40px", "40px"],
            }}
          >
            {svg.logo}
          </Flex>
          {t("sideNav.konnectzITLogoText")}
        </Flex>
        <Flex sx={{ gap: "20px" }}>
          <NavLink to={"/chatgpt"} style={{ textDecoration: "none" }}>
            <DashButton
              sx={{
                fontSize: [16, 16, 18, 18, 18, 20],
                gap: "5px",
                py: ["10px", "15px", "20px", "20px", "22px", "25px"],
                "&:hover": {
                  "& #icon": {
                    fill: "rgb(21,46,115)",
                  },
                  "& #beta": {
                    color: "#fff",
                    bgColor: "#152d73",
                  },
                },
              }}
            >
              <Flex
                id="icon"
                width="1.1rem"
                sx={{ fill: "#f7c544", transition: "fill 0.15s" }}
              >
                {svg_1.chatGPT}
              </Flex>
              ChatGPT
              <Flex
                id="beta"
                sx={{
                  fontSize: 12,
                  transition: "color 0.15s, background 0.15s",
                  alignSelf: "flex-end",
                  color: "#152d73",
                  bgColor: "#f7c544",
                  px: "3px",
                  py: "1px",
                  borderTopLeftRadius: "3px",
                  borderBottomRightRadius: "3px",
                }}
              >
                Beta
              </Flex>
            </DashButton>
          </NavLink>
          <NavLink to={"/konnect"} style={{ textDecoration: "none" }}>
            <DashButton
              onClick={() => {
                dispatch(setEditMode({ mode: false }));
              }}
              sx={{
                fontSize: [16, 16, 18, 18, 18, 20],
                gap: "5px",
                py: ["10px", "15px", "20px", "20px", "22px", "25px"],
                "&:hover": {
                  "& #icon": {
                    fill: "rgb(21,46,115)",
                  },
                },
              }}
            >
              <Flex
                id="icon"
                sx={{ fill: "#f7c544", transition: "fill 0.15s" }}
              >
                {svg_1.createKonnect}
              </Flex>
              {t("header.createKonnectzBtnLabel")}
            </DashButton>
          </NavLink>
          <Menu >
            <MenuButton
              as={Flex}
              sx={{
                cursor: "pointer",
                m: "auto 0px",
                "&:hover": {
                  "& #avatar": {
                    outline: "4px solid rgb(255, 255, 255, 0.5)",
                    border: "2px solid #152D73",
                  },
                },
              }}
            >
              <Avatar
                id="avatar"
                size={{
                  base: "sm",
                  sm: "sm",
                  md: "sm",
                  lg: "md",
                  xl: "md",
                  "2xl": "md",
                }}
                icon={
                  <Flex sx={{ fontSize: 24 }}>
                    {AuthTokenService?.getEmail()?.slice(0, 1)}
                  </Flex>
                }
                bgColor="#fff"
                color="#000"
                sx={{
                  fontWeight: 600,
                  cursor: "pointer",
                  outline: [
                    "4px solid #fff",
                    "4px solid #fff",
                    "4px solid #fff",
                    "0px solid #fff",
                    "0px solid #fff",
                    "0px solid #fff",
                  ],
                  transition: "outline 0.10s, border 0.10s",
                }}
              />
            </MenuButton>
            <MenuList sx={{ p: "10px", width: "270px" }}>
              <MenuItem
                onClick={() => {
                  navigate("/account");
                  dispatch(setRoute("account"));
                }}
                sx={{
                  justifyContent: "start",
                  paddingY: "10px",
                  "&:hover": {
                    "& #icon": {
                      fill: "#f7c544",
                    },
                    "& #label": {
                      color: "#f7c544",
                    },
                  },
                }}
              >
                <Flex
                  sx={{
                    gap: "10px",
                    cursor: "pointer",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => navigate("/account")}
                >
                  <Flex
                    id="icon"
                    sx={{
                      fill: "#2b3344",
                      width: ["18px", "18px", "20px", "20px", "22px", "24px"],
                      height: ["18px", "18px", "20px", "20px", "22px", "24px"],
                    }}
                  >
                    {svg_1.profile}
                  </Flex>
                  <Flex
                    id="label"
                    sx={{
                      m: "auto 0px",
                      fontSize: [16, 16, 16, 18, 18, 18],
                      fontWeight: 600,
                    }}
                  >
                    {t("sideNav.accountLabel")}
                  </Flex>
                </Flex>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/manage-subscription");
                  dispatch(setRoute("Manage-subscription"));
                }}
                sx={{
                  justifyContent: "start",
                  paddingY: "10px",
                  "&:hover": {
                    "& #icon": {
                      fill: "#f7c544",
                    },
                    "& #label": {
                      color: "#f7c544",
                    },
                  },
                }}
              >
                <Flex
                  sx={{
                    gap: "10px",
                    cursor: "pointer",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => navigate("/manage-subscription")}
                >
                  <Flex
                    id="label"
                    sx={{
                      fontSize: "20px",
                      fill: "#2b3344",
                      width: ["18px", "18px", "20px", "20px", "22px", "24px"],
                      height: ["18px", "18px", "20px", "20px", "22px", "24px"],
                    }}
                  >
                    <TbCalendarDollar />
                  </Flex>
                  <Flex
                    id="label"
                    sx={{
                      m: "auto 0px",
                      fontSize: [16, 16, 16, 18, 18, 18],
                      fontWeight: 600,
                    }}
                  >
                    {/* {t("sideNav.accountLabel")} */} Manage Subscription
                  </Flex>
                </Flex>
              </MenuItem>
              <Flex
                onClick={() => {
                  setExpandPrivacy((c) => !c);
                  // navigate("/privacy-policy");
                  // dispatch(setRoute("Privacy Policy"));
                }}
                sx={{
                  justifyContent: "start",
                  paddingY: "10px",
                  px: "10px",
                  "&:hover": {
                    "& #icon": {
                      fill: "#f7c544",
                    },
                    "& #label": {
                      color: "#f7c544",
                    },
                  },
                }}
              >
                <Flex
                  sx={{
                    gap: "10px",
                    width: "100%",
                    cursor: "pointer",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  // onClick={() => navigate("/privacy-policy")}
                >
                  <Flex
                    sx={{
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Flex
                      id="label"
                      sx={{
                        fontSize: "20px",
                        fill: "#2b3344",
                        width: ["18px", "18px", "20px", "20px", "22px", "24px"],
                        height: [
                          "18px",
                          "18px",
                          "20px",
                          "20px",
                          "22px",
                          "24px",
                        ],
                      }}
                    >
                      <MdOutlinePolicy />
                    </Flex>
                    <Flex
                      id="label"
                      sx={{
                        m: "auto 0px",
                        fontSize: [16, 16, 16, 18, 18, 18],
                        fontWeight: 600,
                      }}
                    >
                      Data Policy
                    </Flex>
                  </Flex>
                  <Flex
                    id="label"
                    sx={{
                      fontSize: "20px",
                      fill: "#2b3344",
                      width: ["18px", "18px", "20px", "20px", "22px", "24px"],
                      height: ["18px", "18px", "20px", "20px", "22px", "24px"],
                    }}
                  >
                    {expandPrivacy ? (
                      <IoIosArrowDropup />
                    ) : (
                      <IoIosArrowDropdown />
                    )}
                  </Flex>
                </Flex>
              </Flex>
              {expandPrivacy && (
                <>
                  <MenuItem
                    sx={{
                      justifyContent: "start",
                      "&:hover": {
                        "& #icon": {
                          fill: "#f7c544",
                        },
                        "& #label": {
                          color: "#f7c544",
                        },
                      },
                    }}
                  >
                    <Link
                      href="https://konnectzit.com/privacy-cookie-policy"
                      target="_blank"
                      sx={{
                        paddingY: "10px",
                        gap: "10px",
                        textDecor: "none",
                        width: "100%",
                        cursor: "pointer",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Flex
                        sx={{
                          gap: "10px",
                          alignItems: "center",
                          // justifyContent: "center",
                        }}
                      >
                        <Flex
                          id="label"
                          sx={{
                            fontSize: "14px",
                            fill: "#2b3344",
                            width: [
                              "16px",
                              "16px",
                              "18px",
                              "18px",
                              "20x",
                              "22px",
                            ],
                            height: [
                              "16px",
                              "16px",
                              "18px",
                              "18px",
                              "20x",
                              "22px",
                            ],
                          }}
                        >
                          <MdOutlinePolicy />
                        </Flex>
                        <Flex
                          id="label"
                          sx={{
                            m: "auto 0px",
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                        >
                          Privacy and Cookie Policy
                        </Flex>
                      </Flex>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      justifyContent: "start",
                      "&:hover": {
                        "& #icon": {
                          fill: "#f7c544",
                        },
                        "& #label": {
                          color: "#f7c544",
                        },
                      },
                    }}
                  >
                    <Link
                      href="https://konnectzit.com/privacy-controls"
                      target="_blank"
                      sx={{
                        paddingY: "10px",
                        gap: "10px",
                        textDecor: "none",
                        width: "100%",
                        cursor: "pointer",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Flex
                        sx={{
                          gap: "10px",
                          alignItems: "center",
                          // justifyContent: "center",
                        }}
                      >
                        <Flex
                          id="label"
                          sx={{
                            fontSize: "14px",
                            fill: "#2b3344",
                            width: [
                              "16px",
                              "16px",
                              "18px",
                              "18px",
                              "20x",
                              "22px",
                            ],
                            height: [
                              "16px",
                              "16px",
                              "18px",
                              "18px",
                              "20x",
                              "22px",
                            ],
                          }}
                        >
                          <MdOutlineCookie />
                        </Flex>
                        <Flex
                          id="label"
                          sx={{
                            m: "auto 0px",
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                        >
                          Privacy controls and Cookies
                        </Flex>
                      </Flex>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      justifyContent: "start",
                      "&:hover": {
                        "& #icon": {
                          fill: "#f7c544",
                        },
                        "& #label": {
                          color: "#f7c544",
                        },
                      },
                    }}
                  >
                    <Link
                      href="https://konnectzit.com/terms-and-conditions"
                      target="_blank"
                      sx={{
                        paddingY: "10px",
                        gap: "10px",
                        textDecor: "none",
                        width: "100%",
                        cursor: "pointer",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Flex
                        sx={{
                          gap: "10px",
                          alignItems: "center",
                          // justifyContent: "center",
                        }}
                      >
                        <Flex
                          id="label"
                          sx={{
                            fontSize: "14px",
                            fill: "#2b3344",
                            width: [
                              "16px",
                              "16px",
                              "18px",
                              "18px",
                              "20x",
                              "22px",
                            ],
                            height: [
                              "16px",
                              "16px",
                              "18px",
                              "18px",
                              "20x",
                              "22px",
                            ],
                          }}
                        >
                          <TbNotes />
                        </Flex>
                        <Flex
                          id="label"
                          sx={{
                            m: "auto 0px",
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                        >
                          Terms and Conditions
                        </Flex>
                      </Flex>
                    </Link>
                  </MenuItem>
                </>
              )}
              <MenuItem
                onClick={logout}
                sx={{
                  justifyContent: "start",
                  paddingY: "10px",
                  "&:hover": {
                    "& #icon": {
                      fill: "#f7c544",
                    },
                    "& #label": {
                      color: "#f7c544",
                    },
                  },
                }}
              >
                <Flex
                  sx={{
                    gap: "10px",
                    cursor: "pointer",
                    justifyContent: "center",
                    alignItems: "center",
                    "&:hover": {
                      "& #icon": {
                        fill: "#f7c544",
                      },
                      "& #label": {
                        color: "#f7c544",
                      },
                    },
                  }}
                >
                  <Flex
                    id="icon"
                    sx={{
                      fill: "#2b3344",
                      width: ["18px", "18px", "20px", "20px", "22px", "24px"],
                      height: ["18px", "18px", "20px", "20px", "22px", "24px"],
                    }}
                  >
                    {svg_1.logout}
                  </Flex>
                  <Flex
                    id="label"
                    sx={{
                      m: "auto 0px",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {t("sideNav.logoutLabel")}
                  </Flex>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
};

const Navbar = () => {
  const location = useLocation();
  const activeRoute = useSelector((state) => state.dash.activeRoute);
  const dispatch = useDispatch();

  useEffect(() => {
    menuItems.map((item) => {
      if (item.route === location.pathname) {
        dispatch(setRoute(item.label));
      } else dispatch(setRoute("accounts"));
    });
  }, []);

  return (
    <Flex
      sx={{
        height: "7%",
        minHeight: "40px",
        maxHeight: "50px",
        px: ["60px", "60px", "80px", "80px", "100px", "220px"],
        backgroundColor: "#fff",
        justifyContent: "center",
        boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
      }}
    >
      <Flex
        width="100%"
        sx={{ justifyContent: "space-between", maxWidth: "2000px" }}
      >
        <Flex gap="10px">
          {menuItems.map((item, i) => {
            return (
              <Flex
                sx={{
                  width: ["150px", "150px", "150px", "160px", "160px", "180px"],
                  justifyContent: "center",
                  "&:hover": {
                    borderBottom: "3px solid #f7c544",
                    "& #icon": {
                      fill: "#f7c544",
                    },
                    "& #label": {
                      color: "#f7c544",
                    },
                  },
                  transition: "border 0.25s",
                  borderBottom:
                    location.pathname === item.route
                      ? "3px solid #f7c544"
                      : "3px solid #fff",
                }}
              >
                <NavLink
                  key={i}
                  target={i === 4 ? "_blank" : "_self"}
                  to={item.route}
                  onClick={() => i !== 4 && dispatch(setRoute(item.label))}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                  }}
                >
                  <Flex
                    sx={{
                      gap: "10px",
                      cursor: "pointer",
                      justifyContent: "center",
                      alignItems: "center",
                      "& #icon": {
                        fill:
                          // activeRoute === item.label ||
                          location.pathname === item.route
                            ? "#f7c544"
                            : "#2b3344",
                      },
                      "& #label": {
                        color:
                          // activeRoute === item.label ||
                          location.pathname === item.route
                            ? "#f7c544"
                            : "#2b3344",
                      },
                    }}
                  >
                    <Flex
                      id="icon"
                      sx={{
                        fill: "#2b3344",
                        width: ["18px", "18px", "20px", "20px", "22px", "24px"],
                        transition: "fill 0.25s",
                        height: [
                          "18px",
                          "18px",
                          "20px",
                          "20px",
                          "22px",
                          "24px",
                        ],
                      }}
                    >
                      {item.icon}
                    </Flex>
                    <Flex
                      id="label"
                      sx={{
                        m: "auto 0px",
                        transition: "color 0.25s",
                        fontSize: [16, 16, 16, 18, 18, 18],
                        fontWeight: 600,
                      }}
                    >
                      {item.label}
                    </Flex>
                    {/* <Box
                    id="icon"
                    sx={{
                      display: i === 4 ? 'none' : 'flex',
                      mt: ['2px', '3px', '3px', '4px', '4px', '5px'],
                      fill: '#2b3344',
                      width: ['12px', '14px', '14px', '16px', '18px', '20px'],
                      height: ['12px', '14px', '14px', '16px', '18px', '20px'],
                    }}
                  >
                    {svg_1.expand}
                  </Box> */}
                  </Flex>
                </NavLink>{" "}
              </Flex>
            );
          })}
        </Flex>
        <Flex sx={{ gap: "50px" }}>
          {helpItems.map((item, i) => {
            return (
              <NavLink
                key={i}
                target={i === 0 ? "_blank" : "_self"}
                to={item.route}
                style={{
                  textDecoration: "none",
                  display: "flex",
                }}
              >
                <Flex
                  sx={{
                    gap: "10px",
                    cursor: "pointer",
                    justifyContent: "center",
                    margin: "auto",
                    alignItems: "center",
                    "&:hover": {
                      "& #icon": {
                        fill: "#f7c544",
                      },
                      "& #label": {
                        color: "#f7c544",
                      },
                    },
                  }}
                >
                  <Flex
                    id="icon"
                    sx={{
                      fill: "#2b3344",
                      width: ["18px", "18px", "20px", "20px", "22px", "24px"],
                      height: ["18px", "18px", "20px", "20px", "22px", "24px"],
                    }}
                  >
                    {item.icon}
                  </Flex>
                  <Flex
                    id="label"
                    sx={{
                      m: "auto 0px",
                      fontSize: [16, 16, 16, 18, 18, 18],
                      fontWeight: 600,
                    }}
                  >
                    {item.label}
                  </Flex>
                </Flex>
              </NavLink>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};
